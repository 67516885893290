<template>
	<div id="adaptiveWrapper">
		<div ref="bigScreen" class="homePage bigScreen h100">
			<div class="homePage-content w100 h100">
				<div class="header">
					<div class="header-content">
						<div class="left">
							<span @click="click" class="linghtClass"></span>
							<div>
								<img @click="click" src="@/assets/imgs/title-1.jpg" alt="" />
							</div>
							<Nav></Nav>
						</div>
						<div :style="{ position: 'relative' }" v-if="!timeYear">
							<el-date-picker prefix-icon="0" :clearable="false" class="timeRange" v-model="timeRange"
								type="monthrange" range-separator="至" start-placeholder="开始月份" end-placeholder="结束月份">
							</el-date-picker>
							<img class="timeRangeArrow" src="./assets/timeRangeArrow.jpg">
						</div>
					</div>
					<div class="images w100">
						<img class="w100" src="@/assets/imgs/titleLine.png" alt="" />
						<img class="titleLineLight" src="@/assets/imgs/titleLineLight.png" alt="" />
					</div>
				</div>
				<router-view></router-view>
			</div>
		</div>
	</div>
</template>

<script>
import { formatTimestamp } from '@/utils/formatTime.js';
import Nav from './Nav.vue';
import { mapState } from 'vuex';
import dayjs from 'dayjs'
import screenfull from 'screenfull';


export default {
	name: 'home',
	components: {
		Nav,
	},
	data() {
		return {
			currentTime: [],
			timer: null,
			dataType: 'china',
			timeYear: true,
			valueYear:''
		};
	},
	mounted() {
		this.timer = null;
		this.timer = setInterval(() => {
			this.getCurrentTime();
		}, 1000);
		this.handleScreenAuto();
		window.onresize = this.handleScreenAuto;
	},
	methods: {
    click(){
      screenfull.request(document.getElementById('body'))
    },
		onDataTypeChange(e) {
			this.dataType = e;
		},
		getCurrentTime() {
			let data = new Date();
			let res = formatTimestamp(data);
			res = res.split(' ');
			this.currentTime = [res[0], res[1]];
		},
		handleScreenAuto() {
			const left = this.$store.state.themeConfig.themeConfig.isCollapse ? 64 : 220;
			const designDraftWidth = 1920;

			const curW = window.innerWidth - (screenfull.isFullscreen ? 0 : left);
			const scale = Math.max(curW / designDraftWidth);
			this.$store.state.homePage.scale = scale
			document.getElementById('adaptiveWrapper').style.transform = `scale(${scale})`;
		},
		countMonth(val) {
			let start = val[0]
			let end = val[1]
			let months = []
			while (start <= end) {
				months.push(start.getMonth() + 1)
				start = dayjs(start).add(1, 'month').toDate()
			}

			let date = new Date()
			if (months.length <= 12) {
				this.$store.state.homePage.monthPeriod = 13 - months.length
			} else {
				this.$store.state.homePage.monthPeriod = 1
			}
		}
	},
	computed: {
		...mapState('themeConfig', ['themeConfig']),
		timeRange: {
			get() {
				return this.$store.state.homePage.timeRange
			},
			set(value) {
				this.$store.commit('homePage/setTimeRange', value)
			}
		}
	},
	watch: {
		'themeConfig.isCollapse'() {
			this.handleScreenAuto();
		},
		$route: {
			handler(val) {
				if (val) {
					this.$store.state.themeConfig.themeConfig.isCollapse = true;
					if (val.fullPath == "/home/dashboard/kpi") {
						this.timeYear = true
					}else{
						this.timeYear = false
					}
				}
			},
			deep: true,
			immediate: true,
		},
		timeRange: {
			handler(val) {
				this.countMonth(val)
			}
		}
	},
	destroyed() {
		clearInterval(this.timer);
		window.onresize = null;
	},
};
</script>
<style lang="scss" scoped>
#adaptiveWrapper {
	transition: all 75ms linear;
	display: inline-block;
	width: 1920px;
	height: 1080px;
	transform-origin: 0 0;
}

.homePage {
	background: url('../../assets/imgs/bj.png') no-repeat center;
	background-size: cover;

	.fullBtn {
		margin-bottom: 15px;

		/deep/ .el-button {
			padding: 8px 30px;
			margin-right: 30px;
		}
	}

	&-content {
		background: url('../../assets/imgs/fullBorder.png') no-repeat center;
		background-size: 100% 100%;

		.images {
			position: relative;
			top: -8px;

			.titleLineLight {
				position: absolute;
				left: 0;
				top: -35px;
				pointer-events: none;
			}
		}

		.header-content {
			height: 78px;
			padding: 0 30px;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.left {
				display: flex;
				align-items: center;

				.linghtClass {
					display: inline-block;
					width: 15px;
					height: 15px;
					background-color: #fff;
					box-shadow: 0 0 10px #0db1f4, 0 0 20px #0db1f4, 0 0 30px #0db1f4, 0 0 40px #0db1f4, 0 0 70px #0db1f4, 0 0 80px #0db1f4, 0 0 100px #0db1f4,
						0 0 150px #0db1f4;
					margin-right: 16px;
				}
			}

			.timeAndWeather {
				height: 78px;
				margin-right: 24px;
				margin-top: 6px;
				color: #fff;
				display: flex;
				align-items: center;
				color: #aecaf5;

				p {
					line-height: 20px;
					font-size: 12px;
					color: #aecaf5;
				}

				p:first-child {
					font-size: 20px;
					text-align: end;
				}

				p:last-child {
					text-align: end;
				}

				.divider {
					width: 2px;
					height: 40px;
					background-color: #485b71;
					margin: 0 24px;
				}

				.weather {
					height: 78px;
					display: flex;
					align-items: center;
					justify-content: center;

					.weather-right {
						display: flex;
						flex-direction: column;
						align-items: flex-end;
					}

					img {
						margin-right: 18px;
					}

					.name {
						font-weight: lighter;
					}

					.temperature {
						font-weight: bold;
					}
				}
			}
		}

		.continarClass {
			color: #fff;
			padding: 0 20px;
		}

		/deep/ .el-row {
			margin: 0;
		}
	}
}

/deep/ div::-webkit-scrollbar {
	width: 4px !important;
	/*滚动条宽度*/
	background-color: #031227 !important;
	/*滚动条背景颜色*/
	height: 394px !important;
	/*滚动条高度*/
}

/deep/ div::-webkit-scrollbar-thumb {
	box-shadow: 0px 1px 3px #203a66 inset !important;
	/*滚动条的内阴影*/
	border-radius: 10px !important;
	/*滚动条的圆角*/
	background-color: #203a66 !important;
	/*滚动条的背景颜色*/
}

.timeRange {
	width: 260px;
	padding-right: 26px;
	align-items: center;
	justify-content: space-between;
	font-size: 15px;
	font-weight: bold;
	height: 42px;
	border: none;
	color: white;
	background-image: url("./assets/timeRangeBg.jpg");
	background-repeat: no-repeat;
	background-size: 100% 100%;

	/deep/ .el-input__icon {
		display: none;
	}

	/deep/ .el-range-separator {
		display: flex;
		align-items: center;
		color: white;
		margin-bottom: 1px;
		width: 18px;
	}

	/deep/ .el-range-input {
		color: white;
		font-weight: bold;
		font-size: 15px;
		background-color: transparent;
	}
}

.timeRangeArrow {
	position: absolute;
	right: 12px;
	top: calc(50% - 2px);
}
</style>
